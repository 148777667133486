import { createSignal } from 'solid-js';

export const fetchres = async (url, options) => {
    const req = await fetch(url, options);
    const content = req.headers.get('content-type')?.match('json') ? await req.json() : await req.text();
    if (!req.ok) {
        throw new Error(content?.error?.message || req.statusText);
    }
    return content;
};

export const resource = (f, obj) => {
    const [res, setRes] = createSignal(obj?.value);
    const [loading, setLoading] = createSignal();
    const [error, setError] = createSignal();
    Object.defineProperty(res, 'loading', { get: loading });
    Object.defineProperty(res, 'error', { get: error });
    const fetch = () => {
        setLoading(true);
        new Promise((resolve) => resolve(f())).then((v) => {
            setLoading(false);
            setError();
            setRes(v);
        }).catch((e) => {
            setLoading(false);
            setError(e?.message);
            setRes();
        });
    }
    if (!obj?.lazy && !obj?.value) {
        fetch();
    }
    return [res, { mutate: (v) => setRes(v), refetch: fetch }];
};