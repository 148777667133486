import { createRenderEffect, mergeProps } from "solid-js";
import spinner from './Spinner.module.css';

export default (props) => {
    props = mergeProps({ class: "w-4 h-4" }, props);
    return (
        <div class={`relative ${spinner.spinner} ${props.class}`}>
            <svg
                class="w-full h-full"
                width="100.0001mm"
                height="100.0001mm"
                viewBox="0 0 100.0001 100.0001"
                version="1.1"
                id="svg1"
                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg">
                <defs id="defs1" />
                    <g
                    inkscape:label="Layer 1"
                    inkscape:groupmode="layer"
                    id="layer1">
                    <path
                    id="path1"
                    style="fill:#ffffff;stroke-width:0.264583;-inkscape-stroke:none"
                    d="M 50.000049,0 A 50,50 0 0 0 0,50.000049 50,50 0 0 0 50.000049,100.0001 50,50 0 0 0 100.0001,50.000049 H 94.999886 A 45,45 0 0 1 50.000049,94.999886 45,45 0 0 1 5.0002116,50.000049 45,45 0 0 1 50.000049,5.0002116 Z" />
                </g>
            </svg>
        </div>
    );
}