import { Show, createMemo, createSignal } from 'solid-js';

export default (props) => {
    const [toggle, setToggle] = props.signal || createSignal(false);
    return (
        <div {...props} onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)}>
            {props.children[0]}
            <Show when={toggle()}>
                <div class="h-0 w-full flex flex-col items-center justify-start">
                    <div class="bg-neutral-full rounded-md p-2 relative w-max h-max">
                        {props.children.slice(1)}
                    </div>
                </div>
            </Show>
        </div>
    );
}